/** THEME */
:root {
    --color-primary: #FF6D00;
    --color-secondary: #f2ecec;
    --color-darkgrey: #c0c0c0;
}
body {
    color: #3b3b3b;
    background-color: var(--color-secondary);
}

/* links */
a {
    color: #333;
}
a:hover {
    color: #000;
    text-decoration: none;
}
/* colors */
.color-primary {
    color: var(--color-primary) !important;
}
.color-secondary {
    color: var(--color-secondary) !important;
}
.hover-primary:hover {
    color: var(--color-primary) !important;
}
/* backgrounds */
.bg-primary {
    background-color: var(--color-primary) !important;
}
.bg-secondary {
    background-color: var(--color-secondary) !important;
}
.bg-darkgrey {
    background-color: var(--color-darkgrey) !important;
}
/* button */
.btn.btn-primary {
    background-color: var(--color-primary);
    border-color: var(--color-primary);
}
.btn.btn-outline-primary {
    color: var(--color-primary);
    border-color: var(--color-primary);
}
.btn.btn-outline-primary:hover {
    background-color: transparent;
}
.btn.btn-outline-secondary {
    border-color: var(--color-secondary);
}
.btn {
    border-radius: 0px;
}
.btn.btn-rounded {
    border-radius: 7px;
}
.btn:active,
.btn:focus,
.btn:focus:active {
  outline: 0;
  box-shadow: none;
}
/* card */
.card-header.bg-darkgrey {
    border-bottom-color: var(--color-darkgrey)
}
.card-hover:hover {
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.075)!important;
}
.card-hover {
    transition: all .2s ease-in;
}
.card {
    border: none;
    border-radius: 0.6em;
}
.card-title {
    margin-bottom: 0px;
    padding-bottom: .8rem;
    border-bottom: 1px solid var(--color-primary);
    font-weight: bold;
}
.card .more-options {
    position: absolute;
    right: 1.25rem;
    color: var(--color-primary);
}
.card .dropdown.more-options {
    top: 15px;
}
.card .more-options .dropdown-toggle {
    font-size: 1.25rem;
    padding: 0px;
}
.card .more-options .dropdown-toggle::after {
    display: none;
}
.card thead.border-top-0 th {
    border-top: 0px;
}
/* badge */
.badge-primary {
    background-color: var(--color-primary);
}
/* input group */
.input-group {
    border-radius: 0px;
    border: none;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
}
.input-group .input-group-text {
    border-radius: 0px;
    border: none;
}
.input-group .form-control {
    box-shadow: none;
}
/* form control */
.form-control {
    border-radius: 0px;
    border: none;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.2);
}
/* table */
table * {
    font-size: .9rem !important;
}
table .guest-route-badge {
    font-size: .8rem !important;
}
table .actions i:hover {
    color: var(--color-primary);
}
/* dropdown */
.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent;
}

/** BOOKINGS LIST */
.bookings-list .cancelled a {
    color: #ccc;
    pointer-events: none;
}
.bookings-list .booking-past td {
    background-color: rgba(0,0,0,.08);
}
.bookings-list .row-breakpoint td {
    border-bottom: 1.5px solid #ff6d0080;
}

/** TIMETABLE */
.timetable td {
    text-align: center;
    padding: 15px;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.timetable td:not(.no-hover):hover {
    background-color: var(--color-primary);
    cursor: pointer;
}
.timetable thead td {
    padding-bottom: 20px;
}
.timetable tbody td {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.timetable td.has-bookings {
    background-color: var(--color-primary) !important;
    color: #fff;
}
.timetable td.has-bookings:hover {
    background-color: #994505 !important;
}
.timetable td.has-past-bookings {
    background-color: #994505  !important;
    color: #fff;
}
.timetable-vehicles.row :nth-child(4n-2), .timetable-vehicles.row :nth-child(4n-3) {
    background: #dcdcdc;
}

/** UTILS */
.link-td {
    padding: 0px !important;
    vertical-align: middle;
}
.link-td a {
    padding: .75rem;
    display: block;
}
.dropdown.no-caret .dropdown-toggle::after {
    display: none;
}
.custom-stack {
    position: absolute;
    left: 65px;
    bottom: -25px;
    font-size: 34px;
}

/** COMPONENTS */
.book-aircrew-crew-names .fa {
    visibility: hidden;
}
.book-aircrew-crew-names li:hover .fa {
    visibility: visible;
}
.react-datepicker-wrapper-custom {
    width: 100%;
    display: block
}

.react-datepicker-wrapper-custom input {
    height: calc(1.5em + .75rem + 2px);
    width: 100%;
    display: block;
    box-shadow: 0px 1px 2px rgb(0 0 0 / 20%);
    border: none;
    border-radius: 0px;
    padding:.375rem .75rem;
}

.react-datepicker-wrapper-custom input:disabled {
    background-color: #e9ecef;
    opacity: 1;
}

/** OVERRIDE */
/* bootstrap */
.border.border-primary {
    border-color: var(--color-primary) !important;
}
.modal-backdrop.level-2 {
    z-index: 1050;
}
.table-hover tbody tr:hover {
    cursor: pointer;
}
/* react datepicker */
.modal-datepicker,
.modal-datepicker .react-datepicker__input-container,
.modal-datepicker .react-datepicker__input-container input {
    width: 100%;
}
.small-calendar .react-datepicker {
    border: none !important;
}
.small-calendar .react-datepicker__header {
    background-color: transparent !important;
}
.small-calendar .react-datepicker__day--outside-month {
    color: #aaa !important;
}
.simul-datepicker .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected,
.simul-datepicker .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range,
.simul-datepicker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.small-calendar .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range {
    border-radius: 0px !important;
    outline: none !important;
    background-color: var(--color-primary) !important;
}
.react-datepicker-w100 .react-datepicker-wrapper {
    display: block;
}
.react-datepicker-flex1 {
    flex: 1;
}
.react-datepicker-flex1 .react-datepicker-wrapper {
    width: 100%;
}
/* fontawesome */
.fa-stack-2x {
    font-size: 2em !important;
}
.fa-stack:hover .fa-lock {
    color: var(--color-primary);
}

/** NAV **/
nav.navbar {
    position: sticky;
    top: 7px;
    z-index: 333;
}
.topbar-nav .nav-link {
    font-weight: 500;
    color: #666 !important;
    cursor: pointer;
    padding: 0.2rem 1rem 0.2rem 1.5rem;
    font-size: .9rem;
}
.topbar-nav .nav-link.active {
    color: #333 !important;
}
nav.tab-switching .nav-link.active {
    border-color: #fff #fff #fff !important;
    border-bottom: 3px solid var(--color-primary) !important;
}

/** PRINT **/
.shuttle-timetable-print .table thead tr th {
    border-top: 1px solid var(--color-primary);
}
.shuttle-timetable-print .table tbody {
    border-bottom: 1px solid var(--color-primary);
}

.page-item.active .page-link{
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    
}

.override-pagination li a{
    color: var(--color-primary);
}

.page-link:hover {
    color: var(--color-primary);
}
.page-link {
    color: #000;
}
/** MEDIA QUERIES */
@media screen {
    .hide-on-screen {
        display: none;
    }
 }
