.sidebar {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 3; /* Behind the navbar */
    padding: 0;
    box-shadow: 1px 0px 5px rgba(0, 0, 0, .1);
    background-color: #fff;
}
  
.sidebar-sticky {
    position: sticky;
    /* position: fixed; */
    height: 100vh;
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
    padding-bottom: 15px;
}
  
.sidebar .nav-link {
    font-weight: 500;
    color: #333;
    cursor: pointer;
    padding: 0.2rem 1rem 0.2rem 1.5rem;
    font-size: .9rem;
}

.sidebar-close {
    text-align: right;
    margin-right: 13px;
    margin-top: 3px;
    color: #ccc;
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
}
  
.sidebar .nav-link .feather {
    margin-right: 4px;
    color: #999;
}
  
.sidebar .nav-link.active {
    color: #007bff;
}
  
.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
    color: inherit;
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

.bottom-logo-container {
    position: absolute;
    bottom: 0 !important;
    text-align: center;
    height: 80px;
    width: 100%;
}
.bottom-logo-container .logo {
    height: 100%;
    margin-left: -10px;
}

.upper-logo-container {
    text-align: center;
    height: 90px;
}
.upper-logo-container .logo {
    height: 100%;
    margin-left: -10px;
}

.nav .sub-menu {
    padding: 0px;
}

.nav .sub-menu li .nav-link {
    padding-left: 3.1rem;
}

.nav .sub-menu .sub-menu-items {
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    height: auto;
    max-height: 0px;
    transform-origin: top;
}
.nav .sub-menu.active .sub-menu-items {
    max-height: 500px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
  
/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}
   
/* Handle */
::-webkit-scrollbar-thumb {
    background: rgb(179, 179, 179); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(126, 126, 126); 
}

/* Mobile */
@media screen and (max-width: 767px) {
    .sidebar {
        width: 230px;
        z-index: 999;
        background-color: #fff;
        left: -235px;
        transition: all ease-in-out 0.5s;
    }
    .sidebar.drawer-open {
        width: 230px;
        z-index: 999;
        background-color: #fff;
        left: 0px;
        transition: all ease-in-out 0.5s;
    }
}