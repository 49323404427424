ul.nav li.active a {
    color: var(--color-primary);
    
}
ul.nav li.active {
    border-left: 5px solid var(--color-primary);
}
ul.nav li {
    margin: 5px 0px;
}
.nav-link {
    padding: .3rem 1rem .3rem 1.5rem;
}
.dashboard-template main {
    min-height: 100vh;
    background-color: var(--color-secondary);
}