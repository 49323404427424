.profile-icon {
    background: url('/default-profile.png') no-repeat;
    height: 45px;
    width:45px;
    display: block;
    /* Other styles here */
}

.Toastify__toast--success {
    background: #2ecc71 !important;
}

.Toastify__toast-container {
    width: inherit !important;
} 